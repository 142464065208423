export const FETCH_NODES = 'FETCH_NODES';
export const FETCH_NODES_ERROR = 'FETCH_NODES_ERROR';
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const FETCH_QUESTIONS_ERROR = 'FETCH_QUESTIONS_ERROR';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_TAGS_ERROR = 'FETCH_TAGS_ERROR';

// UI ERROR TYPES
export const SET_ERROR = 'SET_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
